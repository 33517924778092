import React, { useEffect, useState } from "react";
import watsApp from "../../../assets/images/for-pages/whatsapp.png";


import {
  Box,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";

const WhatsAppPopup = () => {

  const KeyFirstLoading = "firstLoading"
  const [open, setOpen] = useState(false)

  const openWhatsApp = () => {
    const phoneNumber = '972525600999';
    const message = encodeURIComponent("היי") + encodeURIComponent(" ") + encodeURIComponent("הגענו דרך קמפיין החורף");
    window.open(`https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${message}&type=phone_number&app_absent=0`, '_blank');
  }
 
  const handleClose = () => {
    setOpen(false);
  };

  const addSessionFirst = () => {sessionStorage.setItem(KeyFirstLoading, '1')}
  const checkSessionFirst = () => {return sessionStorage.getItem(KeyFirstLoading)}
  // const removeSessionFirst = () => {sessionStorage.removeItem(KeyFirstLoading)}

  useEffect(() => {
    if(!!checkSessionFirst()){
    }else {
      setOpen(true);
      addSessionFirst()
    }
  },[])

  const closeButton = (
    <Box
      component={"svg"}
      xmlns="http://www.w3.org/2000/svg"
      width="21px"
      height="21px"
      viewBox="0 0 30 30"
      onClick={handleClose}
      sx={{
        cursor: "pointer",
        position: "absolute",
        top: { xs: "-28px", md: "-34.75px" },
        right: 0,
        width: { xs: "18px", md: "25px" },
        path: {
          transition: "0.4s",
        },
        ":hover path": {
          fill: "black",
        },
      }}
    >
      <path
        id="Icon_ionic-ios-close"
        data-name="Icon ionic-ios-close"
        d="M27.042,24.024l9.1-9.1a2.132,2.132,0,1,0-3.015-3.015l-9.1,9.1-9.1-9.1a2.132,2.132,0,1,0-3.015,3.015l9.1,9.1-9.1,9.1a2.132,2.132,0,0,0,3.015,3.015l9.1-9.1,9.1,9.1a2.132,2.132,0,0,0,3.015-3.015Z"
        transform="translate(-11.285 -11.289)"
        fill="white"
      />
    </Box>
  );
  const OnlyRegistred = (
    <Box
      component={"text"}
      sx={{
        position: "absolute",
        top: { xs: "-35px", md: "-48px" },
        // right: { xs: "-28px", md: "49.75px" },
        right: {xs: "50%", md: "145px" },
        transform: { xs: "translate(50%, 50%)"}
      }}
    >
      <Typography
            lineHeight={{ xs: "18px", md: "24px" }}
            sx={{ fontSize: { xs: "12px", md: "16px" } }}
            letterSpacing={{ xs: "unset", md: "0.29px" }}
            fontFamily={"Heebo-Medium"}
            color={"#FF0000"}
            textAlign={"center"}
          >
            {"משתמש שלא עשה הרשמה לייט"}
          </Typography>
    </Box>
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.2)",
        },
        ".MuiPaper-root": {
          borderRadius: "10px",
          overflowY: "unset",
          margin: { xs: "19px", md: "32px" },
          top: { xs: "0", md: "unset" },
        },
      }}
    >
      {closeButton}
      {/* {!!(!auth?.user) && OnlyRegistred} */}
      <DialogContent
        sx={{
          p: { xs: "30px 19px 40px", md: "33px 81px 65px" },
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          boxShadow: "0px 3px 6px #00000029",
          border: "1px solid #707070",
          position: "relative",
        }}
      >
        <Stack alignItems="center">
          <Typography
            mt={{ xs: "28px", md: "30px" }}
            lineHeight={{ xs: "25px", md: "40px" }}
            sx={{ fontSize: { xs: "22px", md: "26px" } }}
            letterSpacing={{ xs: "unset", md: "0.29px" }}
            fontFamily={"Heebo-Bold"}
            fontWeight={"600"}
            textAlign={"center"}
          >
            {"מתחתנים בחורף?"}
          </Typography>
          <Typography
            mt={{ xs: "16px", md: "11px" }}
            mb={{ xs: "20px", md: "23px" }}
            maxWidth={{ xs: "350px", md: "400px" }}
            lineHeight={{ xs: "25px", md: "32px" }}
            sx={{ fontSize: { xs: "18px", md: "22px" } }}
            letterSpacing={{ xs: "unset", md: "0.29px" }}
            fontFamily={"Heebo-Medium"}
            textAlign={"center"}
          >
            {"סוגרים דרכנו מקום לאירוע וספק נוסף, ומקבלים צלם סטילס מתנה!"}
          </Typography>

            <Box
              backgroundColor={"#31D300"}
              onClick={() => openWhatsApp()}
              sx={{ borderRadius: "5px", 
                cursor: "pointer" ,
                padding: { xs: "11.5px 16.5px", md: "11.5px 16.5px" },
                display: "flex",
                gap: "7.32px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
               <Box
                  sx={{ 
                    maxWidth: { xs: "21px", md: "21px" }, 
                  }}
                  component={"img"}
                  src={watsApp}
                  alt="watsApp"
                />
                <Typography
                  color={"#FFFFFF"}
                  sx={{ fontSize: { xs: "18px", md: "20px" } }}
                  lineHeight={{ xs: "22px", md: "22px" }}
                  fontFamily={"Heebo-Medium"}
                  textAlign={"center"}
                >
                  {"דברו איתנו"}
                </Typography>
            </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default WhatsAppPopup;
